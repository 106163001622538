// Entry point for the build script in your package.json
import '@hotwired/turbo-rails'
import '@hotwired/strada'
import './components/index.js'
import './controllers/index.js'
import './initializers/ahoy.js'
import './initializers/turbo_confirm.js'
import './initializers/frame_missing_handler.js'
import './initializers/honeybadger.js'
import 'chartkick/chart.js'
import 'trix'
import '@rails/actiontext'
import './lib/shoelace.js'
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path'

setBasePath('/packs/shoelace')
