import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['scrollParent', 'left', 'right', 'item']

  connect() {
    window.addEventListener('resize', this._toggleButtonControls.bind(this))
    this.scrollParentTarget.addEventListener('scroll', this._toggleButtonControls.bind(this))
    this._toggleButtonControls()
  }

  scrollLeft() {
    this._nextScrollTarget('left').scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' })
  }

  scrollRight() {
    this._nextScrollTarget('right').scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' })
  }

  _toggleButtonControls() {
    if (this.scrollParentTarget.scrollLeft === 0) {
      this._disable(this.leftTarget)
      this._enable(this.rightTarget)
    } else if (this.scrollParentTarget.scrollLeft === this.maxScroll) {
      this._enable(this.leftTarget)
      this._disable(this.rightTarget)
    } else {
      this._enable(this.leftTarget)
      this._enable(this.rightTarget)
    }
  }

  _disable(button) {
    button.classList.add('btn--disabled')
  }

  _enable(button) {
    button.classList.remove('btn--disabled')
  }

  get maxScroll() {
    return this.scrollParentTarget.scrollWidth - this.scrollParentTarget.clientWidth
  }

  _nextScrollTarget(direction) {
    const targetPercentage = this._nextTargetedScrollPosition(direction) / this.scrollParentTarget.scrollWidth

    const nextIndex = this._directionalRound(direction, targetPercentage * this.itemTargets.length)

    return this.itemTargets[nextIndex]
  }

  _directionalRound(direction, number) {
    return Math[direction === 'left' ? 'ceil' : 'floor'](number)
  }

  _nextTargetedScrollPosition(direction) {
    const amountToScroll = this.scrollParentTarget.clientWidth * (direction === 'left' ? -1 : 1)

    let targetPosition = this.scrollParentTarget.scrollLeft + amountToScroll
    targetPosition = Math.min(targetPosition, this.scrollParentTarget.scrollWidth)
    targetPosition = Math.max(targetPosition, 0)
    return targetPosition
  }
}
