import chroma from 'chroma-js'

function mixColors(color1, color2, ratio) {
  return chroma.mix(color1, color2, ratio).css()
}

export function getCssValueFromVariable(name) {
  window.cachedColors ||= {}
  if (!window.cachedColors[name]) {
    window.cachedColors[name] = getComputedStyle(document.documentElement).getPropertyValue(name)
  }

  return window.cachedColors[name]
}

export function pickGradientColor(color1, color2, position) {
  const inversePosition = 1 - position

  return {
    r: Math.round(color1.r * position + color2.r * inversePosition),
    g: Math.round(color1.g * position + color2.g * inversePosition),
    b: Math.round(color1.b * position + color2.b * inversePosition)
  }
}

export function setAlpha(color, alpha) {
  return chroma(color).alpha(alpha).css()
}

export function pickMultiGradientColor(value, thresholds, exponents, opacity, {
  low, medium, high, max
}) {
  let color
  if (value <= thresholds.LOW) {
    color = chroma(low)
  } else if (value <= thresholds.MEDIUM) {
    const ratio = ((value - thresholds.LOW) / (thresholds.MEDIUM - thresholds.LOW)) ** exponents.LOW_TO_MEDIUM
    color = mixColors(low, medium, ratio)
  } else if (value <= thresholds.HIGH) {
    const ratio = ((value - thresholds.MEDIUM) / (thresholds.HIGH - thresholds.MEDIUM)) ** exponents.MEDIUM_TO_HIGH
    color = mixColors(medium, high, ratio)
  } else if (value <= thresholds.MAX) {
    const ratio = ((value - thresholds.HIGH) / (thresholds.MAX - thresholds.HIGH)) ** exponents.HIGH_TO_MAX
    color = mixColors(high, max, ratio)
  } else {
    color = chroma(max)
  }

  return chroma(color).alpha(opacity).css()
}

export function onColorThemeChange(callback) {
  const colorSchemeQueryList = window.matchMedia('(prefers-color-scheme: dark)')
  colorSchemeQueryList.addEventListener('change', () => {
    window.cachedColors = {}
    callback()
  })
}
