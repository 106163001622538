import { Controller } from '@hotwired/stimulus'

// ==========================================
// 1. CONSTANTS AND CONFIGURATION
// ==========================================

// Selector for finding the chart element
const CHART_SELECTOR = "[data-controller='bank-scatter-chart']"

// ==========================================
// 2. CONTROLLER CLASS DEFINITION
// ==========================================

export default class extends Controller {
  // Defines targets for DOM elements that can be interacted with
  static targets = ['pointSelect', 'resetZoom', 'groupFilter', 'stateFilter', 'sizeMetric']

  // Tracks whether controller has been initialized
  static values = { initialized: Boolean }

  // ==========================================
  // 2.1 LIFECYCLE METHODS
  // ==========================================

  // Sets up mutation observer to watch for chart initialization
  connect() {
    this.observeChartInit()
  }

  // Watches DOM for chart element to be ready
  observeChartInit() {
    const observer = new MutationObserver((_, obs) => {
      const chartElement = this.element.querySelector(CHART_SELECTOR)
      if (chartElement) {
        obs.disconnect()
        this.initializeChartInteraction()
      }
    })

    observer.observe(this.element, {
      childList: true,
      subtree: true
    })
  }

  // Sets up event listeners once chart is ready
  initializeChartInteraction() {
    if (this.initializedValue) return

    this.chartController = this.findChartController()
    if (!this.chartController?.chart) return

    this.setupEventListeners()
    this.initializedValue = true
  }

  // ==========================================
  // 2.2 EVENT SETUP AND HANDLING
  // ==========================================

  // Initializes zoom and click event listeners
  setupEventListeners() {
    this.setupZoomListener()
    this.setupChartClickListener()
  }

  // Listens for chart zoom events
  setupZoomListener() {
    this.element.addEventListener('bank-scatter-chart:zoom:change', this.handleZoomChange.bind(this))
  }

  // Listens for chart point click events
  setupChartClickListener() {
    this.element.addEventListener('bank-scatter-chart:point:clicked', this.handleChartPointClick.bind(this))
  }

  // ==========================================
  // 2.3 FILTER HANDLERS
  // ==========================================

  // Handles group filter changes -> Now only handles scale changes
  handleGroupFilter(event) {
    const chart = this.getChart()
    if (!chart) return

    const value = event.target.value
    if (this.hasPointSelectTarget && this.pointSelectTarget.tomSelect) {
      this.pointSelectTarget.tomSelect.clear()
    }

    // Only update group scales
    chart.handleGroupFilter(value)
  }

  // Handles state filter changes -> Now directly calls handleStateFilter
  handleStateFilter(_event) {
    const chart = this.getChart()
    if (!chart) return

    const tomSelectElement = this.stateFilterTarget.closest('[data-controller~="tom-select"]')
    if (!tomSelectElement) return

    const tomSelectController = this.application.getControllerForElementAndIdentifier(
      tomSelectElement,
      'tom-select'
    )

    if (!tomSelectController?.tomSelect) return

    // Get current states and highlighted point
    const states = tomSelectController.tomSelect.getValue()
    const highlightedPoint = chart.getHighlightedPoint()

    // Apply the state filter directly
    chart.handleStateFilter(states)

    // If we had a highlighted point, make sure it's still selected in TomSelect
    if (highlightedPoint
      && this.hasPointSelectTarget
      && this.pointSelectTarget?.tomSelect) {
      this.pointSelectTarget.tomSelect.setValue(highlightedPoint.id, true)
    }
  }

  // Handles point size metric changes -> Calls chart_controller.handlePointSize()
  handlePointSize(event) {
    const chart = this.getChart()
    if (!chart) return

    const value = event.target.value
    chart.handlePointSize(value)
  }

  // ==========================================
  // 2.4 POINT SELECTION HANDLERS
  // ==========================================

  // Handles point selection from dropdown -> Calls chart_controller.handlePointSelect()
  handlePointSelect(_event) {
    const chart = this.getChart()
    if (!chart) return

    const tomSelectElement = this.pointSelectTarget.closest('[data-controller~="tom-select"]')
    if (!tomSelectElement) return

    const tomSelectController = this.application.getControllerForElementAndIdentifier(
      tomSelectElement,
      'tom-select'
    )

    if (!tomSelectController?.tomSelect) return

    const value = tomSelectController.tomSelect.getValue()

    // If no value, clear the selection and update chart
    if (!value) {
      chart.handlePointSelect({ target: { value: null } })
      return
    }

    // Always allow the highlight to be set, even if the point is filtered out
    chart.handlePointSelect({ target: { value } })
  }

  // Updates point select dropdown when point is clicked on chart
  handleChartPointClick(event) {
    if (!this.hasPointSelectTarget) return

    const tomSelectElement = this.pointSelectTarget.closest('[data-controller~="tom-select"]')
    if (!tomSelectElement) return

    const tomSelectController = this.application.getControllerForElementAndIdentifier(
      tomSelectElement,
      'tom-select'
    )

    if (!tomSelectController?.tomSelect) return

    const { id } = event.detail.point
    if (!id) return

    tomSelectController.tomSelect.setValue(id)
  }

  // ==========================================
  // 2.5 ZOOM HANDLERS
  // ==========================================

  // Shows/hides reset zoom button based on zoom state
  handleZoomChange(event) {
    const method = event.detail.isZoomed ? 'remove' : 'add'
    this.resetZoomTarget.classList[method]('visually-hidden')
  }

  // Resets zoom level -> Calls chart_controller.resetZoom()
  reset() {
    if (!this.chartController) return

    this.chartController.resetZoom()
    this.resetZoomTarget.classList.add('visually-hidden')
  }

  // ==========================================
  // 2.6 UTILITY METHODS
  // ==========================================

  // Gets or creates chart controller instance
  getChart() {
    if (!this.chartController) {
      this.chartController = this.findChartController()
    }
    return this.chartController
  }

  // Finds chart controller in DOM
  findChartController() {
    const chartElement = this.element.querySelector(CHART_SELECTOR)
    if (!chartElement) return null

    return this.application.getControllerForElementAndIdentifier(
      chartElement,
      'bank-scatter-chart'
    )
  }
}
