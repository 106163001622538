import FlatpickrController from 'stimulus-flatpickr'
import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr'
import throttle from '../utilities/throttle.js'

export default class extends FlatpickrController {
  static targets = ['startDate', 'startDateValue', 'endDate', 'endDateValue']

  initialize() {
    this.originalShowMonths = Number(this.data.get('showMonths') || '1')
    this.minScreenWidth = 650
    this._resizeFunc = throttle(this._handleResponsive.bind(this), 500)

    this.config = {
      nextArrow: this._icon('east'),
      prevArrow: this._icon('west'),
      closeOnSelect: false,
      locale: {
        weekdays: {
          shorthand: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
          longhand: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        }
      },
      plugins: this.plugins
    }
  }

  connect() {
    super.connect()

    this._handleResponsive()
    window.addEventListener('resize', throttle(this._resizeFunc))
  }

  disconnect() {
    super.disconnect()
    window.removeEventListener('resize', this._resizeFunc)
  }

  setStartDate() {
    if (this.fp.selectedDates.length > 1) {
      this.changing = 'start'
      this.fp.setDate(this.fp.selectedDates[1], true)
    } else {
      this.fp.setDate(null, true)
    }
  }

  setEndDate() {
    if (this.fp.selectedDates.length === 1) {
      this._activateButton('end')
      return
    }

    if (this.fp.selectedDates.length > 1) {
      this.changing = 'end'
      this.fp.setDate(this.fp.selectedDates[0], true)
    }
  }

  get plugins() {
    if (this.data.get('inline') === 'true') {
      return []
    }
    return [
      ShortcutButtonsPlugin({
        button: [
          { label: 'Cancel', attributes: { class: 'btn', type: 'button' } },
          { label: 'Done', attributes: { class: 'btn-primary margin-left-xs', type: 'button' } }
        ],
        onClick(index, flatpickr) {
          if (index === 0) {
            flatpickr.clear()
          }
          flatpickr.close()
        }
      })
    ]
  }

  ready(_selectedDates, _dateStr, fp) {
    const date = new Date()
    const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0)

    // Set current month on the right pane so we aren't showing disabled months
    if (fp.config.showMonths > 1 && fp.config.maxDate && fp.config.maxDate <= endOfMonth) {
      fp.changeMonth(-1)
    }
  }

  change(selectedDates, _dateStr, _flatpickr) {
    if (!this.hasStartDateTarget || !this.hasEndDateTarget) {
      return
    }
    this._activateButton('start')

    if (this.changing === 'start') {
      this._removeDateOnButton(this.startDateValueTarget)
    } else if (this.changing === 'end') {
      this._removeDateOnButton(this.endDateValueTarget)
    } else if (selectedDates.length === 0) {
      this._removeDateOnButton(this.startDateValueTarget)
      this._removeDateOnButton(this.endDateValueTarget)
    } else if (selectedDates.length === 1) {
      this._setDateOnButton(this.startDateValueTarget, selectedDates[0])
      this._removeDateOnButton(this.endDateValueTarget)
    } else {
      this._activateButton('end')
      this._setDateOnButton(this.startDateValueTarget, selectedDates[0])
      this._setDateOnButton(this.endDateValueTarget, selectedDates[1])
    }
    this.changing = null
  }

  _activateButton(type) {
    if (type === 'end') {
      this._setActiveButton(this.endDateTarget)
      this._setInactiveButton(this.startDateTarget)
    } else {
      this._setActiveButton(this.startDateTarget)
      this._setInactiveButton(this.endDateTarget)
    }
  }

  _setActiveButton(button) {
    button.classList.add('btn-primary')
  }

  _setInactiveButton(button) {
    button.classList.remove('btn-primary')
  }

  _setDateOnButton(button, date) {
    button.innerText = this.fp.formatDate(date, 'm/d/Y')
    button.classList.add('flatpickr__date-range-value--selected')
  }

  _removeDateOnButton(button) {
    button.innerText = 'Select Date'
    button.classList.remove('flatpickr__date-range-value--selected')
  }

  clear(event) {
    event.stopPropagation()

    this.fp.clear()
    this.fp.close()
  }

  _icon(name) {
    return `<button type="button" class="btn btn--icon btn--small">
      <span class="material-symbols-outlined">${name}</span>
    </button>`
  }

  _handleResponsive() {
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

    if (screenWidth < this.minScreenWidth && this.config.showMonths === this.originalShowMonths) {
      this._updateShowMonths(1)
    } else if (screenWidth >= this.minScreenWidth && this.config.showMonths !== this.originalShowMonths) {
      this._updateShowMonths(this.originalShowMonths)
    }
  }

  _updateShowMonths(showMonths) {
    this.fp.destroy()
    this.data.set('showMonths', showMonths)
    super.connect()
  }
}
