import { Controller } from '@hotwired/stimulus'
import Chart from 'chart.js/auto'
import zoomPlugin from 'chartjs-plugin-zoom'
import annotationPlugin from 'chartjs-plugin-annotation'
import chroma from 'chroma-js'
import { getCssValueFromVariable, onColorThemeChange, pickMultiGradientColor } from '../utilities/color.js'

Chart.register(zoomPlugin, annotationPlugin)

// ==========================================
// 1. CONSTANTS AND CONFIGURATION
// ==========================================

// Theme color variables for chart styling
const THEME_COLORS = {
  text: '--gc-color-chart-text',
  altText: '--gc-color-chart-alt-text',
  grid: '--gc-chart-grid-color',
  border: '--op-color-neutral-plus-three',
  group: '--gc-color-chart-group',
  tooltipBg: '--gc-color-chart-tooltip-background',
  tooltipBorder: '--gc-color-chart-tooltip-border',
  tooltipText: '--gc-color-chart-tooltip-text',
  highlightColor: '--gc-color-chart-highlight',
  highlightBorder: '--gc-color-chart-highlight-border',
  highlightLabel: '--gc-color-chart-highlight-label-background',
  highlightText: '--gc-color-chart-highlight-label-text',
  highlightLine: '--gc-color-chart-highlight-line',
  thresholdLine: '--gc-color-chart-threshold-line',
  thresholdLabel: '--gc-color-chart-threshold-label-background',
  thresholdText: '--gc-color-chart-threshold-label-text',
  riskLow: '--gc-color-chart-range-low',
  riskMedium: '--gc-color-chart-range-mid',
  riskHigh: '--gc-color-chart-range-high',
  riskMax: '--gc-color-chart-range-max'
}

// Constants for chart configuration
const CHART_CONSTANTS = {
  POINT_SIZE: {
    DEFAULT: 5,
    MIN: 4,
    MID: 8,
    MAX: 14,
    LOW_THRESHOLD_PERCENT: 0.35,
    HIGH_THRESHOLD_PERCENT: 0.85
  },
  RISK: {
    THRESHOLDS: {
      LOW: 2,
      MEDIUM: 3.5,
      HIGH: 5.0,
      MAX: 10.0
    },
    GRADIENT: {
      LOW_TO_MEDIUM: 1.25,
      MEDIUM_TO_HIGH: 1.75,
      HIGH_TO_MAX: 4.5
    }
  },
  POINT_GROUPS: {
    small: { min: 0, max: 50 },
    medium: { min: 50, max: 250 },
    large: { min: 250, max: 500 },
    very_large: { min: 500, max: null }
  }
}

// ==========================================
// 2. CONTROLLER CLASS DEFINITION
// ==========================================

// Primary controller for managing the bank risk visualization chart using Chart.js
export default class extends Controller {
  // Static properties for Stimulus and Chart configuration:
  // - values: Bank data points for visualization
  // - targets: DOM elements for chart rendering and bank selection
  // - CACHED_POINT_COLORS: Color calculation cache for performance
  // - POINT_GROUP_RANGES: Bank group range definitions for filtering
  static values = {
    data: Array,
    xAxisLabel: String,
    yAxisLabel: String,
    multiLabelPrefix: String
  }

  static targets = ['canvas', 'pointSelect']
  static CACHED_POINT_COLORS = new Map()
  static get POINT_GROUP_RANGES() { return CHART_CONSTANTS.POINT_GROUPS }

  // Instance properties for chart state
  pointSizeField = null
  pointSizeMin = null
  pointSizeMax = null
  currentGroup = ''
  selectedStates = []

  // ==========================================
  // 2.1 LIFECYCLE METHODS
  // ==========================================

  // Initializes the controller, sets up initial state and chart instance
  // Subscribes to color theme changes for dark/light mode support
  connect() {
    this.currentGroup = ''
    this.selectedStates = []
    this.initializeChart()
    onColorThemeChange(() => {
      if (this.chart) {
        this.chart.update()
      }
    })
  }

  // Properly cleans up chart instance to prevent memory leaks
  disconnect() {
    if (this.chart) {
      this.chart.destroy()
    }
  }

  initializeChart() {
    this.chart = new Chart(this.canvasTarget, {
      type: 'scatter',
      data: this.dataConfig,
      options: this.chartOptions
    })
  }

  // ==========================================
  // 32.2 CHART CONFIGURATION GETTERS
  // ==========================================

  get dataConfig() {
    return {
      datasets: [{
        data: this.dataValue,
        radius: (context) => {
          if (context?.raw?.hidden) return 0 // Hide points by setting radius to 0
          if (!this.pointSizeField || !context?.raw) return CHART_CONSTANTS.POINT_SIZE.DEFAULT
          const value = context.raw[this.pointSizeField]
          return value != null ? this.calculatePointSize(value) : CHART_CONSTANTS.POINT_SIZE.DEFAULT
        },
        hoverRadius: (context) => {
          if (!this.pointSizeField || !context?.raw) return CHART_CONSTANTS.POINT_SIZE.DEFAULT
          const value = context.raw[this.pointSizeField]
          return value != null ? this.calculatePointSize(value) : CHART_CONSTANTS.POINT_SIZE.DEFAULT
        },
        backgroundColor: (context) => this.calculatePointColor(context),
        borderColor: 'transparent',
        borderWidth: 0,
        drawActiveElementsOnTop: false
      }]
    }
  }

  // Core chart configuration getters
  get chartOptions() {
    return {
      animation: this.animationConfig,
      interaction: this.interactionConfig,
      maintainAspectRatio: false,
      onClick: this.handleChartClick.bind(this),
      onHover: this.handleChartHover.bind(this),
      plugins: this.pluginConfig,
      responsive: true,
      scales: this.scalesConfig,
      transitions: this.transitionsConfig,
      clip: {
        left: 5, right: 10, top: 20, bottom: 10
      },
      defaults: { borderColor: this.getColor('grid') },
      layout: { autoPadding: false }
    }
  }

  // Animation and interaction configs
  get animationConfig() {
    return {
      duration: 400,
      easing: 'easeInOutQuart'
    }
  }

  get interactionConfig() {
    return {
      mode: 'point',
      intersect: true
    }
  }

  get transitionsConfig() {
    return {
      default: {
        animation: {
          duration: 400,
          easing: 'easeInOutQuart'
        }
      }
    }
  }

  // Plugin configs
  get pluginConfig() {
    return {
      zoom: this.zoomConfig,
      legend: this.legendConfig,
      tooltip: this.tooltipConfig,
      annotation: this.annotationConfig
    }
  }

  get annotationConfig() {
    return {
      clip: true,
      annotations: {
        thresholdLine: {
          type: 'line',
          yMin: 5,
          yMax: 5,
          borderColor: this.getColor('thresholdLine'),
          borderWidth: 1,
          label: {
            backgroundColor: this.getColor('thresholdLabel'),
            borderRadius: 4,
            color: this.getColor('thresholdText'),
            content: 'Risk Threshold',
            display: true,
            font: { size: 12, weight: '400' },
            padding: {
              top: 2, bottom: 2, left: 8, right: 8
            },
            position: 'end'
          }
        },
        midpointLine: {
          type: 'line',
          yMin: CHART_CONSTANTS.RISK.THRESHOLDS.MEDIUM,
          yMax: CHART_CONSTANTS.RISK.THRESHOLDS.MEDIUM,
          borderColor: this.getColor('grid'),
          borderWidth: 1,
          borderDash: [8, 5],
          drawTime: 'beforeDatasetsDraw'
        },
        highlightCircle: {
          type: 'point',
          init: () => ({
            x: 0, y: 200, width: 50, height: 50
          }),
          xValue: (ctx) => this.getHighlightedValue(ctx, 'x'),
          yValue: (ctx) => this.getHighlightedValue(ctx, 'y'),
          radius: (ctx) => {
            const point = this.getHighlightedPoint(ctx)
            if (!point || !this.pointSizeField) return CHART_CONSTANTS.POINT_SIZE.DEFAULT + 3
            return this.calculatePointSize(point[this.pointSizeField]) + 3
          },
          backgroundColor: 'transparent',
          borderColor: this.getColor('highlightColor'),
          borderWidth: 2,
          display: (ctx) => !!this.getHighlightedPoint(ctx),
          drawTime: 'afterDatasetsDraw',
          clip: false
        },
        highlightLabel: {
          type: 'label',
          content: (ctx) => {
            const point = this.getHighlightedPoint(ctx)
            if (!point) return ''
            return [
              point.label_title,
              point.label_description
            ]
          },
          xValue: (ctx) => this.getHighlightedValue(ctx, 'x'),
          yValue: (ctx) => this.getHighlightedValue(ctx, 'y'),
          xAdjust: (ctx) => this.calculateHighlightOffset(ctx, 'x', 220),
          yAdjust: (ctx) => this.calculateHighlightOffset(ctx, 'y', 60),
          font: { size: 13, weight: 'bold' },
          textAlign: 'left',
          color: this.getColor('highlightText'),
          backgroundColor: this.getColor('highlightLabel'),
          borderColor: this.getColor('highlightLine'),
          borderWidth: 2,
          borderRadius: 2,
          padding: {
            top: 6, bottom: 6, left: 12, right: 12
          },
          callout: {
            display: true,
            side: 20,
            borderColor: this.getColor('highlightLine'),
            borderWidth: 2
          }
        },
        groupRangeBox: {
          type: 'box',
          clip: true,
          xMin: (_ctx) => {
            if (!this.currentGroup) return 0
            const range = this.constructor.POINT_GROUP_RANGES[this.currentGroup]
            return range.min
          },
          xMax: (_ctx) => {
            if (!this.currentGroup) return 0
            const range = this.constructor.POINT_GROUP_RANGES[this.currentGroup]
            const visibleData = this.chart.data.datasets[0].data
            return range.max || Math.max(...visibleData
              .filter((d) => this.isInGroup(d.x, this.currentGroup))
              .map((d) => d.x))
          },
          yMin: 0,
          yMax: 10,
          backgroundColor: chroma(this.getColor('group')).alpha(0.4).css(),
          borderWidth: 0,
          display: (ctx) => {
            const point = this.getHighlightedPoint(ctx)
            return !!(this.currentGroup && point && !this.isInGroup(point.x, this.currentGroup))
          },
          drawTime: 'beforeDraw'
        }
      }
    }
  }

  get legendConfig() {
    return {
      align: 'end',
      display: true,
      position: 'top',
      onClick: null,
      labels: {
        usePointStyle: true,
        pointStyle: 'circle',
        font: { size: 13 },
        boxPadding: 4,
        boxWidth: 12,
        boxHeight: 10,
        generateLabels: () => [
          { text: 'Low Risk', fillStyle: this.getColor('riskLow') },
          { text: 'Medium Risk', fillStyle: this.getColor('riskMedium') },
          { text: 'High Risk', fillStyle: this.getColor('riskHigh') }
        ].map((label, index) => ({
          ...label,
          strokeStyle: 'transparent',
          fontColor: this.getColor('altText'),
          hidden: false,
          index,
          datasetIndex: 0
        }))
      }
    }
  }

  get tooltipConfig() {
    return {
      backgroundColor: this.getColor('tooltipBg'),
      borderColor: this.getColor('tooltipBorder'),
      borderWidth: 1,
      cornerRadius: 4,
      titleFont: { size: 14 },
      bodyFont: { size: 13 },
      titleColor: this.getColor('tooltipText'),
      bodyColor: this.getColor('tooltipText'),
      bodySpacing: 4,
      boxPadding: 4,
      padding: 16,
      usePointStyle: true,
      caretSize: 8,
      caretPadding: 10,
      drawTime: 'afterDatasetsDraw',
      callbacks: {
        title: (tooltipItems) => {
          if (tooltipItems.length === 1) {
            return tooltipItems[0].raw.label_title
          }
          return `${this.multiLabelPrefixValue} (${tooltipItems.length})`
        },
        label: (tooltipItem) => {
          const data = tooltipItem.raw
          const tooltipItems = tooltipItem.chart.tooltip.dataPoints
          const baseMetrics = data.label_description

          if (tooltipItems.length === 1) return baseMetrics
          return [data.label_title, baseMetrics]
        }
      }
    }
  }

  get zoomConfig() {
    return {
      limits: {
        x: { min: 0, max: 'original' }
      },
      zoom: {
        mode: 'x',
        wheel: { enabled: true },
        pinch: { enabled: true },
        onZoomComplete: () => this.handleZoomComplete()
      },
      drag: false,
      pan: {
        enabled: true,
        mode: 'x'
      }
    }
  }

  // Scale config
  get scalesConfig() {
    return {
      x: {
        type: 'linear',
        grid: { color: this.getColor('grid') },
        border: {
          display: true,
          color: this.getColor('border'),
          width: 1
        },
        ticks: {
          color: this.getColor('altText'),
          align: 'inner',
          callback: (value) => `${this.formatCurrency(value)}M`,
          autoSkip: true,
          autoSkipPadding: 10,
          maxTicksLimit: 12
        },
        title: {
          color: this.getColor('altText'),
          display: true,
          text: this.xAxisLabelValue,
          padding: { top: 8, bottom: 0 }
        },
        position: 'bottom',
        bounds: 'data',
        beginAtZero: true,
        suggestedMin: 0,
        suggestedMax: 1000
      },
      y: {
        type: 'linear',
        grid: { color: this.getColor('grid') },
        border: {
          display: true,
          color: this.getColor('border'),
          width: 1
        },
        ticks: { color: this.getColor('altText') },
        title: {
          color: this.getColor('altText'),
          display: true,
          text: this.yAxisLabelValue
        },
        position: 'left',
        bounds: 'data',
        beginAtZero: true,
        suggestedMin: 0,
        suggestedMax: 10
      }
    }
  }

  calculateHighlightOffset(ctx, axis, maxOffset) {
    const point = this.getHighlightedPoint(ctx)
    if (!point) return 0

    const scale = ctx.chart.scales[axis]
    const value = point[axis]
    const range = scale.max - scale.min
    const position = (value - scale.min) / range

    // For Y-axis, if point is in top 25%, move label down
    if (axis === 'y' && position > 0.75) {
      return maxOffset
    }

    // For X-axis, move label left/right based on position
    if (axis === 'x') {
      return position > 0.5 ? -maxOffset : maxOffset
    }

    // Default Y-axis behavior for lower points
    return -maxOffset
  }

  // ==========================================
  // 2.3 DATA MANAGEMENT METHODS
  // ==========================================

  // Core data filtering and processing
  filterDataset(data, { states = this.selectedStates, group = this.currentGroup } = {}) {
    return data.filter((point) => {
      const stateMatch = !states?.length || states.includes(point.state)
      const groupMatch = !group || this.isInGroup(point.x, group)
      return stateMatch && groupMatch
    })
  }

  isInGroup(value, group) {
    const range = this.constructor.POINT_GROUP_RANGES[group]
    if (!range) return true
    return value >= (range.min || 0) && (range.max === null || value <= range.max)
  }

  // Point size management
  updatePointSizes() {
    if (this.pointSizeField) {
      const values = this.dataValue
        .filter((point) => {
          const stateMatch = !this.selectedStates?.length || this.selectedStates.includes(point.state)
          const groupMatch = !this.currentGroup || this.isInGroup(point.x, this.currentGroup)
          return stateMatch && groupMatch
        })
        .map((point) => point[this.pointSizeField])
        .filter((value) => value !== undefined)

      this.pointSizeMin = values.length > 0 ? Math.min(...values) : 0
      this.pointSizeMax = values.length > 0 ? Math.max(...values) : 0
    } else {
      this.pointSizeMin = null
      this.pointSizeMax = null
    }
  }

  // Highlight management
  ensureHighlightedPoint(filteredData, highlightedPoint) {
    if (highlightedPoint && !filteredData.find((p) => p.id === highlightedPoint.id)) {
      return [...filteredData, { ...highlightedPoint, highlight: true }]
    }
    return filteredData
  }

  getHighlightedPoint(_ctx) {
    const filteredPoint = this.chart?.data?.datasets?.[0]?.data.find((d) => d.highlight)
    if (filteredPoint) return filteredPoint
    return this.dataValue.find((d) => d.highlight)
  }

  getHighlightedValue(ctx, axis) {
    const point = this.getHighlightedPoint(ctx)
    return point ? point[axis] : 0
  }

  clearHighlights() {
    this.chart.data.datasets[0].data.forEach((point) => {
      point.highlight = false
    })
  }

  setHighlight(pointId) {
    this.clearHighlights()
    const point = this.chart.data.datasets[0].data.find((p) => p.id === pointId)
    if (point) {
      point.highlight = true
      this.chart.update()
    }
  }

  // Scale management
  updateScalesForGroup(group, highlightedPoint) {
    const range = this.constructor.POINT_GROUP_RANGES[group]

    if (group && range) {
      // Get filtered data points for the current group
      const groupPoints = this.dataValue
        .filter((d) => this.isInGroup(d.x, this.currentGroup))
        .map((d) => d.x)

      // Set safe default if no points match
      const groupMax = groupPoints.length > 0
        ? Math.max(...groupPoints)
        : (range.max || 1000) // Use range.max or fallback to 1000

      const padding = Math.max((groupMax - range.min) * 0.05, 10) // Minimum 10 units padding

      if (highlightedPoint && !this.isInGroup(highlightedPoint.x, group)) {
        if (highlightedPoint.x < range.min) {
          this.chart.options.scales.x.min = Math.max(0, highlightedPoint.x - padding)
          this.chart.options.scales.x.max = groupMax
        } else {
          this.chart.options.scales.x.min = range.min
          this.chart.options.scales.x.max = highlightedPoint.x + padding
        }
      } else {
        this.chart.options.scales.x.min = range.min
        this.chart.options.scales.x.max = groupMax
      }
    } else {
      // Simple reset with safe defaults
      this.chart.options.scales.x.min = undefined
      this.chart.options.scales.x.max = undefined
      this.chart.options.scales.x.suggestedMin = 0
      this.chart.options.scales.x.suggestedMax = 1000
    }
  }

  ensurePointVisible(point) {
    if (this.currentGroup) {
      const range = this.constructor.POINT_GROUP_RANGES[this.currentGroup]
      if (range) {
        // Get filtered data points for the current group
        const groupPoints = this.dataValue
          .filter((d) => this.isInGroup(d.x, this.currentGroup))
          .map((d) => d.x)

        // Set safe default if no points match
        const groupMax = groupPoints.length > 0
          ? Math.max(...groupPoints)
          : (range.max || 1000) // Use range.max or fallback to 1000

        if (!this.isInGroup(point.x, this.currentGroup)) {
          if (point.x < range.min) {
            this.chart.options.scales.x.min = Math.max(0, point.x - (groupMax - range.min) * 0.1)
            this.chart.options.scales.x.max = groupMax
          } else {
            this.chart.options.scales.x.min = range.min
            this.chart.options.scales.x.max = point.x + (groupMax - range.min) * 0.1
          }
        } else {
          this.chart.options.scales.x.min = range.min
          this.chart.options.scales.x.max = groupMax
        }
      }
    } else {
      const xScale = this.chart.scales.x
      if (point.x < xScale.min || point.x > xScale.max) {
        const padding = Math.max((xScale.max - xScale.min) * 0.1, 10) // Minimum 10 units padding
        this.chart.options.scales.x.min = Math.max(0, point.x - padding)
        this.chart.options.scales.x.max = point.x + padding
      }
    }

    this.chart.update()
  }

  // ==========================================
  // 2.4 EVENT HANDLERS
  // ==========================================

  handleStateFilter(states) {
    this.selectedStates = states
    const highlightedPoint = this.getHighlightedPoint()

    // Filter data based on current states
    let filteredData = this.filterDataset(this.dataValue)
    filteredData = this.ensureHighlightedPoint(filteredData, highlightedPoint)

    // Update the dataset with filtered points
    this.chart.data.datasets[0].data = filteredData

    this.updatePointSizes()
    this.chart.update()
  }

  handleGroupFilter(group = '') {
    this.currentGroup = group
    const highlightedPoint = this.getHighlightedPoint()

    // Filter data based on current group and states
    let filteredData = this.filterDataset(this.dataValue)
    filteredData = this.ensureHighlightedPoint(filteredData, highlightedPoint)

    // Update the dataset with filtered points
    this.chart.data.datasets[0].data = filteredData

    // Adjust X axis scale based on group
    if (group) {
      const range = this.constructor.POINT_GROUP_RANGES[group]
      if (range) {
        const visiblePoints = filteredData.filter((p) => !p.highlight || this.isInGroup(p.x, group))
        const xMax = range.max || Math.max(...visiblePoints.map((p) => p.x))

        // If highlighted point exists and is outside the group range
        if (highlightedPoint && !this.isInGroup(highlightedPoint.x, group)) {
          if (highlightedPoint.x < range.min) {
            // Highlighted point is to the left of group
            this.chart.options.scales.x.min = highlightedPoint.x
            this.chart.options.scales.x.max = xMax
          } else {
            // Highlighted point is to the right of group
            this.chart.options.scales.x.min = range.min
            this.chart.options.scales.x.max = highlightedPoint.x
          }
        } else {
          // No highlighted point outside range, use exact group boundaries
          this.chart.options.scales.x.min = range.min
          this.chart.options.scales.x.max = xMax
        }
      }
    } else {
      // Reset only X axis scale when no group is selected
      this.chart.options.scales.x.min = undefined
      this.chart.options.scales.x.max = undefined
    }

    // Update point sizes for the new filtered dataset
    this.updatePointSizes()

    this.chart.update()
  }

  // Resets chart zoom level to default view
  // Used when user clicks reset zoom button
  resetZoom() {
    if (!this.chart) return
    this.chart.resetZoom()
  }

  // Notifies parent components about zoom state changes
  // Enables/disables reset zoom button visibility
  handleZoomComplete() {
    const isZoomed = this.isZoomed()
    this.element.dispatchEvent(new CustomEvent('bank-scatter-chart:zoom:change', {
      bubbles: true,
      detail: { isZoomed }
    }))
  }

  // Handles user clicks on chart points
  // Highlights selected point and notifies parent components
  handleChartClick(_event, elements) {
    if (!elements.length) return

    const clickedPoint = elements[0].element.$context.raw
    if (!clickedPoint) return

    // Check if this point should be visible in current filters
    const shouldBeVisible = this.filterDataset([clickedPoint]).length > 0
    if (!shouldBeVisible) return

    this.setHighlight(clickedPoint.id)

    this.element.dispatchEvent(new CustomEvent('bank-scatter-chart:point:clicked', {
      bubbles: true,
      detail: { point: clickedPoint }
    }))
  }

  // Updates cursor style for better UX when hovering over points
  handleChartHover(event, chartElement) {
    event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
  }

  // Processes bank selection from dropdown
  // Adjusts chart view to ensure selected bank is visible
  // Handles special case when no bank is selected
  handlePointSelect(event) {
    const pointId = event?.target?.value
    this.clearHighlights()

    if (!pointId) {
      if (this.currentGroup) {
        const range = this.constructor.POINT_GROUP_RANGES[this.currentGroup]
        if (range) {
          const groupMax = range.max || Math.max(...this.dataValue
            .filter((d) => this.isInGroup(d.x, this.currentGroup))
            .map((d) => d.x))
          this.chart.options.scales.x.min = range.min
          this.chart.options.scales.x.max = groupMax
        }
      } else {
        delete this.chart.options.scales.x.min
        delete this.chart.options.scales.x.max
      }

      this.chart.update()
      return
    }

    const originalPoint = this.dataValue.find((point) => point.id === pointId)
    if (!originalPoint) return

    originalPoint.highlight = true

    let filteredData = [...this.chart.data.datasets[0].data]
    filteredData = filteredData.filter((point) => !point.highlight)

    if (!filteredData.find((p) => p.id === pointId)) {
      filteredData.push({ ...originalPoint, highlight: true })
    } else {
      const filteredPoint = filteredData.find((p) => p.id === pointId)
      filteredPoint.highlight = true
    }

    this.chart.data.datasets[0].data = filteredData

    const selectedPoint = filteredData.find((p) => p.id === pointId)
    if (selectedPoint) {
      this.ensurePointVisible(selectedPoint)
    }

    this.chart.update()
  }

  // Updates point sizing based on selected metric (cash, equity, or real estate)
  handlePointSize(field) {
    this.pointSizeField = field
    this.updatePointSizes()
    this.chart.update()
  }

  // ==========================================
  // 2.5 CALCULATION AND UTILITY METHODS
  // ==========================================

  // Calculates point color based on risk value with caching for performance
  // Uses color gradients between risk thresholds with rounded caching
  calculatePointColor(context) {
    if (!context?.raw) return 'transparent'

    const value = context.raw.y
    if (value == null) return 'transparent'

    // Round to 2 decimal places for cache key to prevent memory bloat
    const cacheKey = Math.round(value * 100) / 100
    if (this.constructor.CACHED_POINT_COLORS.has(cacheKey)) {
      return this.constructor.CACHED_POINT_COLORS.get(cacheKey)
    }

    const { THRESHOLDS, GRADIENT } = CHART_CONSTANTS.RISK

    // Calculate color based on risk thresholds with smooth transitions
    const color = pickMultiGradientColor(value, THRESHOLDS, GRADIENT, 0.8, {
      low: this.getColor('riskLow'),
      medium: this.getColor('riskMedium'),
      high: this.getColor('riskHigh'),
      max: this.getColor('riskMax')
    })

    // Cache calculated color for future use with rounded key
    this.constructor.CACHED_POINT_COLORS.set(cacheKey, color)

    // Clear cache if it grows too large (optional safety measure)
    if (this.constructor.CACHED_POINT_COLORS.size > 1000) {
      this.constructor.CACHED_POINT_COLORS.clear()
    }

    return color
  }

  // Calculates point size based on metric value and defined thresholds
  // Uses interpolation for smooth size transitions between thresholds
  calculatePointSize(value) {
    const {
      MIN, MID, MAX, LOW_THRESHOLD_PERCENT, HIGH_THRESHOLD_PERCENT
    } = CHART_CONSTANTS.POINT_SIZE
    const range = this.pointSizeMax - this.pointSizeMin

    if (Math.abs(range) < Number.EPSILON) return MID

    const lowThreshold = this.pointSizeMin + (range * LOW_THRESHOLD_PERCENT)
    const highThreshold = this.pointSizeMin + (range * HIGH_THRESHOLD_PERCENT)

    if (Math.abs(highThreshold - lowThreshold) < Number.EPSILON) {
      return MID
    }

    // Interpolate size based on value's position within thresholds
    if (value <= lowThreshold) {
      return this.interpolateRange(value, this.pointSizeMin, lowThreshold, MIN, MID)
    } if (value <= highThreshold) {
      return this.interpolateRange(value, lowThreshold, highThreshold, MID, MAX)
    }
    return this.interpolateRange(value, highThreshold, this.pointSizeMax, MID, MAX)
  }

  // Safely interpolates a value from one range to another
  // Handles edge cases like zero-width ranges and out-of-bounds values
  interpolateRange(value, fromMin, fromMax, toMin, toMax) {
    // Use relative epsilon that scales with the magnitude of the values
    const relativeEpsilon = Math.max(
      Math.abs(fromMax),
      Math.abs(fromMin)
    ) * Number.EPSILON * 100

    // Check if range is effectively zero using relative epsilon
    if (Math.abs(fromMax - fromMin) <= relativeEpsilon) {
      return (toMin + toMax) / 2
    }

    const clampedValue = Math.max(fromMin, Math.min(fromMax, value))
    const normalizedPosition = (clampedValue - fromMin) / (fromMax - fromMin)
    return toMin + normalizedPosition * (toMax - toMin)
  }

  // ==========================================
  // 3.6 HELPER METHODS
  // ==========================================

  // Retrieves theme-aware color values from CSS variables
  getColor(name) {
    return getCssValueFromVariable(THEME_COLORS[name])
  }

  // Formats currency values with USD formatting and no decimal places
  formatCurrency(value) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    }).format(value)
  }

  // Determines if chart is currently zoomed by comparing current and default ranges
  // Uses small tolerance to handle floating-point imprecision
  isZoomed() {
    if (!this.chart) return false

    // Check if zoom plugin is in a zoomed state
    return this.chart.isZoomedOrPanned()
  }
}
