import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    matchOn: String,
    matchOff: String,
    attribute: {
      type: String,
      default: 'disabled'
    },
    watch: {
      type: Boolean,
      default: true
    }
  }

  connect() {
    const mql = window.matchMedia(this.query)
    this.element[this.attributeValue] = mql.matches

    if (this.watchValue) {
      mql.onchange = (e) => {
        this.element[this.attributeValue] = e.matches
      }
    }
  }

  get query() {
    if (!this.hasMatchOnValue && !this.hasMatchOffValue) {
      throw new Error('Either match-on or match-off must be set')
    }

    let operator = null
    if (this.hasMatchOnValue) {
      if (this.matchOnValue === 'mobile') {
        operator = '<='
      } else if (this.matchOnValue === 'desktop') {
        operator = '>'
      }
    }
    if (this.hasMatchOffValue) {
      if (this.matchOffValue === 'mobile') {
        operator = '>'
      } else if (this.matchOffValue === 'desktop') {
        operator = '<='
      }
    }

    return `(width ${operator} 512px)`
  }
}
