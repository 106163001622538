import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  submit() {
    this.element.requestSubmit()
  }

  updateSort(event) {
    event.preventDefault()
    const form = this.element
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)

    const sortValue = form.querySelector("select[name='sort']").value
    params.set('sort', sortValue)

    window.location.href = `${url.pathname}?${params.toString()}`
  }
}
