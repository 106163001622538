import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['input', 'disabledElement']
  static values = {
    url: String,
    wholeForm: Boolean,
    count: Number,
    reFocus: { type: Boolean, default: true },
    disabledElementClass: { type: String, default: 'fetch-disabled' }
  }

  isRunning = false
  queue = {}

  async perform(
    { currentTarget: { name, value, tomselect }, params: { url: urlParam, value: valueParam, additionalQuery } }
  ) {
    if (this.isRunning && this.#hasQueue(urlParam)) return
    if (this.isRunning) {
      this.#addToQueue(name, value, tomselect, urlParam, valueParam, additionalQuery)
      return
    }

    this.isRunning = true
    if (this.hasDisabledElementTarget) {
      this.disabledElementTarget.classList.add(this.disabledElementClassValue)
    }

    // eslint-disable-next-line no-param-reassign
    if (tomselect) value = tomselect.items

    if (!this.reFocusValue) {
      document.activeElement.blur()
    }

    const fetchURL = new URL(urlParam || this.urlValue)
    const query = new URLSearchParams(fetchURL.search)

    if (additionalQuery) {
      Object.keys(additionalQuery).forEach((key) => query.append(key, additionalQuery[key]))
    }

    if (this.wholeFormValue) {
      const form = new FormData(this.element)
      form.forEach((formValue, key) => query.append(key, formValue))
    } else if (this.hasInputTarget) {
      this.inputTargets.forEach((input) => query.set(input.name, this.#valueFrom(input)))
    } else {
      query.set(name, valueParam ?? value)
    }

    const response = await get(fetchURL, { query, responseKind: 'turbo-stream' })

    if (this.hasDisabledElementTarget) {
      this.disabledElementTarget.classList.remove(this.disabledElementClassValue)
    }
    this.isRunning = false
    if (this.#hasAnyInQueue()) {
      this.#performNextInQueue()
    }

    if (response.ok) this.countValue += 1
  }

  #hasAnyInQueue() {
    return Object.keys(this.queue).length > 0
  }

  #hasQueue(url) {
    return this.#hasAnyInQueue() && Object.prototype.hasOwnProperty.call(this.queue, url)
  }

  #addToQueue(name, value, tomselect, urlParam, valueParam, additionalQuery) {
    this.queue[urlParam] = {
      currentTarget: { name, value, tomselect }, params: { url: urlParam, value: valueParam, additionalQuery }
    }
  }

  #performNextInQueue() {
    const url = Object.keys(this.queue)[0]
    const request = this.queue[url]
    delete this.queue[url]

    this.perform(request)
  }

  #valueFrom(input) {
    if (input.type === 'checkbox') {
      return input.checked
    }
    if (input.tomselect) {
      return input.tomselect.items
    }
    if (input.multiple) {
      return [...input.selectedOptions].map((o) => o.value)
    }

    return input.value
  }
}
