import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  updateInput({ params: { type, value } }) {
    const hiddenInput = [...document.querySelectorAll(`[name*="${type}"]`)].find((input) => !input.disabled)

    if (hiddenInput.tagName.toLowerCase() === 'select') {
      [...hiddenInput.options].forEach((option) => {
        if (option.value === value) {
          option.selected = true
        }
      })
    } else {
      hiddenInput.value = value
    }

    hiddenInput.dispatchEvent(new Event('change'))
  }

  clear() {
    this.inputTarget.value = ''
    this.inputTarget.dispatchEvent(new Event('input'))
  }
}
